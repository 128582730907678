/* eslint-disable @next/next/no-img-element */
import styles from './ModelSelectionCombobox.module.scss';
import React, { Fragment } from 'react';
import TextField from '@/atoms/TextField';
import SearchIcon from '@/icons/SearchIcon';
import {
  SmdBasSrpModelsDependantFilterOptionValue,
  SmdBasSrpModelsFilterOptionValue,
  SmdBasSrpModelsSelectedFilterValue,
} from 'types/api';
import { useFloatingList } from '@/hooks/useFloatingList';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { Item } from '@/atoms/Combobox';
import cx from 'classnames';
import ChevronDown from '@/icons/ChevronDown';
import LargeCloseIcon from '@/icons/LargeCloseIcon';
import { flattenOptionValues } from '@/utils';
import fuzzysort from 'fuzzysort';

// To be able to style the highlight of a searchText within the filtered options
// and to avoid using dangerouslySetInnerHTML: https://github.com/farzher/fuzzysort/issues/66#issuecomment-602167743
const highlight = (r: Fuzzysort.Result | undefined, f: any, s = '\x01') =>
  fuzzysort
    .highlight(r, s, s)!
    .split(s)
    .map((s, i) =>
      i % 2
        ? f(s, i)
        : s.replace(/^\s+|\s+$/g, (match) => {
            // for the remaining non-highlighted text, make sure to also render any potential spaces at the start & end, in the html
            return '\xa0'.repeat(match.length);
          })
    );

type ComboboxItemsProps = {
  options: SmdBasSrpModelsDependantFilterOptionValue[];
  multiSelect?: boolean;
  activeIndex: number | null;
  getComboboxItemProps: (
    option: any,
    index: any,
    optionParent: SmdBasSrpModelsDependantFilterOptionValue
  ) => Record<string, unknown>;
  values: SmdBasSrpModelsSelectedFilterValue[];
};

type FuzzySortItemsProps = ComboboxItemsProps & { searchText: string };

const getFuzzySortItem = (
  getComboboxItemProps: ComboboxItemsProps['getComboboxItemProps'],
  activeIndex: ComboboxItemsProps['activeIndex'],
  multiSelect: ComboboxItemsProps['multiSelect'],
  values: ComboboxItemsProps['values'],
  option: Fuzzysort.KeysResult<SmdBasSrpModelsFilterOptionValue>,
  index: number,
  dependantOptionParent?: SmdBasSrpModelsDependantFilterOptionValue
) => (
  <Item
    {...getComboboxItemProps(option.obj, index, dependantOptionParent!)}
    active={activeIndex === index}
    style={{ fontWeight: 'bold' }}
  >
    {multiSelect ? (
      <>
        <input
          type="checkbox"
          name={option.obj.value + ''}
          checked={
            values.flatMap((v) => v.values)?.includes(option.obj.value!) ??
            false
          }
          readOnly
        />
        <span>
          {highlight(option[0], (m: any, i: any) => (
            <span style={{ fontWeight: 'normal' }} key={i}>
              {m}
            </span>
          ))}
        </span>
      </>
    ) : (
      <span>
        {highlight(option[0], (m: any, i: any) => (
          <span style={{ fontWeight: 'normal' }} key={i}>
            {m}
          </span>
        ))}
      </span>
    )}
  </Item>
);

const getComboboxItems =
  (
    getComboboxItemProps: ComboboxItemsProps['getComboboxItemProps'],
    activeIndex: ComboboxItemsProps['activeIndex'],
    multiSelect: ComboboxItemsProps['multiSelect'],
    values: ComboboxItemsProps['values'],
    optionParent: SmdBasSrpModelsDependantFilterOptionValue
  ) =>
  // eslint-disable-next-line react/display-name
  (
    option: SmdBasSrpModelsFilterOptionValue & {
      image?: string;
      level?: number;
    },
    index: number
  ) =>
    (
      <Item
        {...getComboboxItemProps(option, index, optionParent)}
        active={activeIndex === index}
        style={{ paddingLeft: 16 + (option.level ?? 0) * 16 }}
      >
        {multiSelect ? (
          <>
            <input
              type="checkbox"
              name={option.value + ''}
              checked={
                values.flatMap((v) => v.values)?.includes(option.value!) ??
                false
              }
              readOnly
            />
            {option.name}
          </>
        ) : (
          option.name
        )}
      </Item>
    );

export const FuzzySortItems = ({
  options,
  searchText,
  multiSelect,
  activeIndex,
  getComboboxItemProps,
  values,
}: FuzzySortItemsProps) => {
  const results = React.useMemo(() => {
    const allOptionValues = options.flatMap((o) =>
      flattenOptionValues(o.optionValues!)
    );
    return fuzzysort.go(searchText, allOptionValues, {
      limit: 10,
      keys: ['name'],
    });
  }, [searchText, options]);

  if (results.length === 0) {
    return (
      <div className={styles.noResults}>
        Der blev ikke fundet nogen resultater.
      </div>
    );
  }

  const getDependantOptionParent = (
    option: SmdBasSrpModelsFilterOptionValue
  ) => {
    const optionParent = options.find(
      (o) =>
        !!flattenOptionValues(o.optionValues!)?.find(
          (ov) => ov.value === option.value
        )
    );
    return optionParent;
  };
  return (
    <>
      {results.map((option, index) => (
        <Fragment key={`${option.obj.value}${index}`}>
          {getFuzzySortItem(
            getComboboxItemProps,
            activeIndex,
            multiSelect,
            values,
            option,
            index,
            getDependantOptionParent(option.obj)
          )}
        </Fragment>
      ))}
    </>
  );
};

const ComboboxItems = ({
  options,
  multiSelect,
  activeIndex,
  getComboboxItemProps,
  values,
}: ComboboxItemsProps) => {
  return (
    <>
      {options.map((option, index) => (
        <Fragment key={`${option.parentValue}${index}`}>
          <Item className={styles.listSectionHeadline} active={false}>
            {option.parentValue}
          </Item>
          {flattenOptionValues(option.optionValues!).map(
            getComboboxItems(
              getComboboxItemProps,
              activeIndex,
              multiSelect,
              values,
              option
            )
          )}
        </Fragment>
      ))}
    </>
  );
};

type Props = {
  values: SmdBasSrpModelsSelectedFilterValue[];
  onChange: (value: any) => void;
  multiSelect?: boolean;
  options: SmdBasSrpModelsDependantFilterOptionValue[];
  disabled?: boolean;
  onClear: () => void;
};

export const ModelSelectionCombobox = ({
  values,
  options,
  onChange,
  disabled,
  multiSelect = true,
  onClear,
}: Props) => {
  const [searchText, setSearchText] = React.useState('');
  const containerRef = React.useRef<HTMLDivElement>(null);
  const searchTextFieldRef = React.useRef<HTMLInputElement>(null);

  const getMaxHeight = React.useCallback(
    (availableHeight: number) => '400px',
    []
  );

  const {
    x,
    y,
    strategy,
    refs,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    activeIndex,
    setOpen,
    open,
    listRef,
  } = useFloatingList(
    undefined,
    false,
    getMaxHeight,
    undefined,
    'bottom-start',
    '232px',
    false
  );

  const allOptionValues = React.useMemo(() => {
    return options.flatMap((o) => flattenOptionValues(o.optionValues!));
  }, [options]);

  const selectedOptions = React.useMemo(() => {
    return values
      ?.flatMap((v) => v.values)
      .map((value) => allOptionValues.find((o) => o.value === value))
      .filter(Boolean);
  }, [values, allOptionValues]);

  let textContent = 'Model';
  if (selectedOptions && selectedOptions?.length > 0) {
    if (selectedOptions.length === allOptionValues.length) {
      textContent = 'Alle';
    } else {
      textContent = selectedOptions?.map((o) => o?.name).join(', ');
    }
  }

  const handleOnChangeSearchText = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  const getComboboxItemProps = (
    option: SmdBasSrpModelsFilterOptionValue & {
      level?: number | undefined;
    },
    index: number,
    optionParent: SmdBasSrpModelsDependantFilterOptionValue
  ) =>
    getItemProps({
      key: `${option.value}${index}`,
      ref(node) {
        listRef.current[index] = node;
      },
      onClick() {
        onChange({
          parent: {
            key: optionParent.parentKey,
            value: optionParent.parentValue,
          },
          value: option.value!,
        });

        if (!multiSelect) {
          setOpen(false);
          refs.domReference.current?.focus();
        }
      },
    });

  React.useEffect(() => {
    // once the filter is disabled, clear the selected options;
    // to avoid an extra dispatch on mount, do it only if there are selected options already in the combobox
    if (
      !!onClear &&
      disabled &&
      selectedOptions &&
      selectedOptions?.length > 0
    ) {
      onClear();
    }
  }, [disabled, selectedOptions, onClear]);

  React.useEffect(() => {
    // set focus within the search text field when the dropdown is opened
    if (open) {
      // make sure the page is not scrolled to the top
      setTimeout(
        () => searchTextFieldRef.current?.focus({ preventScroll: true }),
        0
      );
    }
  }, [open]);

  return (
    <div ref={containerRef}>
      <div
        {...getReferenceProps({
          ref: refs.setReference,
          className: cx(styles.wrapper, styles.selectionCombobox, {
            [styles.disabled]: disabled,
          }),
          onClick: () => (!disabled ? setOpen((prev) => !prev) : undefined),
          tabIndex: !disabled ? 0 : undefined,
          ['aria-disabled']: disabled,
        })}
      >
        <div
          className={cx(styles.content, {
            // [styles.placeholder]: !hasValue,
          })}
        >
          {textContent}
        </div>
        <div className={styles.actions}>
          <button
            aria-label="Åbn dropdown"
            aria-disabled={disabled}
            tabIndex={!disabled ? 0 : -1}
          >
            <ChevronDown
              className={cx(styles.icons, {
                [styles.open]: open,
              })}
            />
          </button>
        </div>
      </div>
      {open && (
        <FloatingPortal root={containerRef}>
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: styles.popover,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                  display: 'flex',
                  flexDirection: 'column',
                },
              })}
            >
              <div className={styles.searchTextWrapper}>
                <TextField
                  value={searchText ? searchText : ''}
                  onChange={handleOnChangeSearchText}
                  startElement={
                    <SearchIcon
                      style={{
                        marginLeft: 'var(--padding-inner-sm)',
                        color: 'var(--color-grey-2)',
                        flex: '1 0 auto',
                      }}
                    />
                  }
                  endElement={
                    !!searchText && (
                      <button
                        onClick={clearSearchText}
                        className={styles.clearSearchText}
                      >
                        <LargeCloseIcon height={16} width={16} />
                      </button>
                    )
                  }
                  placeholder="Søg"
                  wrapperProps={{ className: styles.searchTextField }}
                  ref={searchTextFieldRef}
                />
              </div>
              <div className={styles.itemsList}>
                {searchText.length > 0 ? (
                  <FuzzySortItems
                    searchText={searchText}
                    options={options}
                    values={values}
                    getComboboxItemProps={getComboboxItemProps}
                    multiSelect={multiSelect}
                    activeIndex={activeIndex}
                  />
                ) : (
                  <ComboboxItems
                    options={options}
                    values={values}
                    getComboboxItemProps={getComboboxItemProps}
                    multiSelect={multiSelect}
                    activeIndex={activeIndex}
                  />
                )}
              </div>
              <button
                onClick={onClear}
                disabled={values.length === 0}
                className={cx(styles.resetButton, {
                  [styles.disabled]: values.length === 0,
                })}
              >
                Nulstil
              </button>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};
