import React from 'react';
import { Car as GalleryCar, Gallery } from './Gallery';
import styles from './Galleries.module.scss';
import ChevronDown from '@/icons/ChevronDown';
import { useInfiniteQuery } from '@tanstack/react-query';
import { GetExtraPromotedCars, carToGalleryCar } from 'api/frontpage';

const defaultPageSize = 12;

export const ExtraPromotedCars = () => {
  const {
    data: cars,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<
    Awaited<ReturnType<typeof GetExtraPromotedCars>>,
    Error,
    GalleryCar
  >(
    ['extraPromotedCars'],
    ({ pageParam = 1 }) => GetExtraPromotedCars(pageParam, defaultPageSize),
    {
      staleTime: 1000 * 60 * 10,
      cacheTime: 1000 * 60 * 10,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) =>
        allPages.length > 10 ? undefined : allPages.length + 1, // If we have more than 10 pages, we don't want to fetch more
      select: (data) => ({
        pageParams: data.pageParams,
        pages: data.pages.flat().map(carToGalleryCar),
      }),
    }
  );

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>BRUGTE PRIVATE BILER TIL SALG</h2>
      <div className={styles.gallery}>
        <Gallery
          cars={cars?.pages.flat() ?? []}
          recommendationItem="PrivateRelated"
        />
      </div>
      <div className={styles.buttonContainer}>
        {isLoading ? (
          <div>Henter flere...</div>
        ) : hasNextPage ? (
          <button
            className={styles.button}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isLoading}
          >
            <span className={styles.buttonText}>
              Se flere private biler <ChevronDown />
            </span>
          </button>
        ) : null}
        {error && <p>Øv! Der skete en fejl - prøv igen senere</p>}
      </div>
    </section>
  );
};

export default ExtraPromotedCars;
