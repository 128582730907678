import styles from './ReadOnlyGroupingFilterDropdownInput.module.scss';
import React from 'react';
import cx from 'classnames';
import ChevronDown from '@/icons/ChevronDown';

type Props = {
  placeholder?: string;
  textContent: string;
  disabled?: boolean;
  'data-e2e'?: string;
};

export const ReadOnlyGroupingFilterDropdownInput = ({
  placeholder,
  textContent,
  disabled,
  'data-e2e': datae2e,
}: Props) => {
  return (
    <div
      className={cx(
        styles.wrapper,
        styles.fullWidth,
        styles.selectionCombobox,
        {
          [styles.disabled]: disabled,
        }
      )}
      tabIndex={!disabled ? 0 : -1}
      aria-disabled={disabled}
    >
      <div
        className={cx(styles.content, {
          // [styles.placeholder]: !hasValue,
        })}
      >
        {textContent || placeholder || 'Vælg'}
      </div>
      <div className={styles.actions}>
        <button
          aria-label="Åbn dropdown"
          aria-disabled={disabled}
          tabIndex={!disabled ? 0 : -1}
        >
          <ChevronDown
            className={cx(styles.icons, {
              [styles.open]: false,
            })}
          />
        </button>
      </div>
    </div>
  );
};
