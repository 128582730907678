import React from 'react';
import linkGroups from './data/linkGroup.json';
import styles from './LinkGroup.module.scss';

export const LinkGroup = () => {
  return (
    <div>
      {linkGroups.map((group, index) => (
        <section key={index} className={styles.container}>
          <h2>{group.title}</h2>
          <div className={styles.wrapper}>
            {group.links ? (
              <ul className={styles.list}>
                {group.links.map((link, index) => (
                  <li key={index}>
                    <a href={link.href} rel="bookmark">
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>{group.description}</p>
            )}
          </div>
        </section>
      ))}
    </div>
  );
};
