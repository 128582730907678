/* eslint-disable @next/next/no-img-element */
import styles from './SelectionCombobox.module.scss';
import React from 'react';
import TextField from '@/atoms/TextField';
import SearchIcon from '@/icons/SearchIcon';
import {SmdBasSrpModelsFilterOption, SmdBasSrpModelsFilterOptionValue} from '../../../types/api';
import { useFloatingList } from '@/hooks/useFloatingList';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { Item, FuzzySortItems } from '@/atoms/Combobox';
import cx from 'classnames';
import ChevronDown from '@/icons/ChevronDown';
import LargeCloseIcon from '@/icons/LargeCloseIcon';

type ComboboxItemsProps = {
  topRanks?: Array<SmdBasSrpModelsFilterOptionValue & { image: string }>;
  options: Array<SmdBasSrpModelsFilterOptionValue & { level?: number }>;
  multiSelect?: boolean;
  activeIndex: number | null;
  getComboboxItemProps: (option: any, index: any) => Record<string, unknown>;
  values: (string | number)[] | undefined;
};

const getItems =
  (
    getComboboxItemProps: ComboboxItemsProps['getComboboxItemProps'],
    activeIndex: ComboboxItemsProps['activeIndex'],
    multiSelect: ComboboxItemsProps['multiSelect'],
    values: ComboboxItemsProps['values'],
    startIndex?: number // in case of extra items at the top, in the list (ex. topRanks)
  ) =>
  // eslint-disable-next-line react/display-name
  (
    option: SmdBasSrpModelsFilterOptionValue & {
      image?: string;
      level?: number;
    },
    index: number
  ) =>
    (
      <Item
        {...getComboboxItemProps(
          option,
          startIndex ? startIndex + index : index
        )}
        active={
          startIndex
            ? activeIndex === startIndex + index
            : activeIndex === index
        }
        className={styles.item}
        style={{
          paddingLeft: 16 + (option.level ?? 0) * 16,
          gap: option.image
            ? 'var(--padding-inner-xxs)'
            : 'var(--padding-inner-xs)',
        }}
      >
        {multiSelect ? (
          <>
            <input
              type="checkbox"
              name={option.value + ''}
              checked={values?.includes(option.value!) ?? false}
              readOnly
            />
            {option.image && (
              <img
                width="28px"
                height="28px"
                src={option.image}
                alt={option.name!}
              />
            )}
            <span style={{ lineHeight: '22px' }}>{option.name}</span>
          </>
        ) : (
          option.name
        )}
      </Item>
    );

const ComboboxItems = ({
  topRanks,
  options,
  multiSelect,
  activeIndex,
  getComboboxItemProps,
  values,
}: ComboboxItemsProps) => {
  return (
    <>
      {topRanks && topRanks.length > 0 && (
        <>
          <Item className={styles.listSectionHeadline} active={false}>
            Populære mærker
          </Item>
          {topRanks.map(
            getItems(getComboboxItemProps, activeIndex, multiSelect, values)
          )}
        </>
      )}
      <>
        { topRanks && topRanks?.length > 0 &&
          <Item className={styles.listSectionHeadline} active={false}>
            Alle mærker
          </Item>
        }
        {options.map(
          getItems(
            getComboboxItemProps,
            activeIndex,
            multiSelect,
            values,
            topRanks?.length
          )
        )}
      </>
    </>
  );
};

type Props = {
  filter: SmdBasSrpModelsFilterOption;
  topRanked: Array<SmdBasSrpModelsFilterOptionValue & { image: string }>;
  values: Array<string | number>;
  onChange: (value: string | number) => void;
  multiSelect?: boolean;
  options: Array<SmdBasSrpModelsFilterOptionValue & { level?: number }>;
  disabled?: boolean;
  onClear: () => void;
  textSearch?: boolean;
};

export const SelectionCombobox = ({
  filter,
  values,
  options,
  onChange,
  topRanked,
  disabled,
  multiSelect = true,
  onClear,
  textSearch = true,
}: Props) => {
  const [searchText, setSearchText] = React.useState('');
  const containerRef = React.useRef<HTMLDivElement>(null);
  const searchTextFieldRef = React.useRef<HTMLInputElement>(null);

  const getMaxHeight = React.useCallback(
    (availableHeight: number) => '400px',
    []
  );

  const {
    x,
    y,
    strategy,
    refs,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    activeIndex,
    setOpen,
    open,
    listRef,
  } = useFloatingList(
    undefined,
    false,
    getMaxHeight,
    undefined,
    'bottom-start',
    '232px',
    false
  );

  const selectedOptions = React.useMemo(
    () =>
      values
        ?.map((value) => options.find((o) => o.value === value))
        .filter(Boolean),
    [values, options]
  );

  let textContent = filter.title ?? 'Vælg...';
  if (selectedOptions && selectedOptions?.length > 0) {
    if (selectedOptions.length === options.length) {
      textContent = 'Alle';
    } else {
      textContent = selectedOptions?.map((o) => o?.name).join(', ');
    }
  }

  const handleOnChangeSearchText = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  const getComboboxItemProps = (
    option: SmdBasSrpModelsFilterOptionValue & {
      level?: number | undefined;
    },
    index: number
  ) =>
    getItemProps({
      key: `${option.value}${index}`,
      ref(node) {
        listRef.current[index] = node;
      },
      onClick() {
        onChange(option.value!);

        if (!multiSelect) {
          setOpen(false);
          refs.domReference.current?.focus();
        }
      },
    });

  React.useEffect(() => {
    // once the filter is disabled, clear the selected options;
    // to avoid an extra dispatch on mount, do it only if there are selected options already in the combobox
    if (
      !!onClear &&
      disabled &&
      selectedOptions &&
      selectedOptions?.length > 0
    ) {
      onClear();
    }
  }, [disabled, selectedOptions, onClear]);

  React.useEffect(() => {
    // set focus within the search text field when the dropdown is opened
    if (open) {
      // make sure the page is not scrolled to the top
      setTimeout(
        () => searchTextFieldRef.current?.focus({ preventScroll: true }),
        0
      );
    }
  }, [open]);

  return (
    <div ref={containerRef}>
      <div
        {...getReferenceProps({
          ref: refs.setReference,
          className: cx(styles.wrapper, styles.selectionCombobox, {
            [styles.disabled]: disabled,
          }),
          onClick: () => (!disabled ? setOpen((prev) => !prev) : undefined),
          tabIndex: !disabled ? 0 : undefined,
          ['aria-disabled']: disabled,
        })}
      >
        <div
          className={cx(styles.content, {
            // [styles.placeholder]: !hasValue,
          })}
        >
          {textContent}
        </div>
        <div className={styles.actions}>
          <button
            aria-label="Åbn dropdown"
            aria-disabled={disabled}
            tabIndex={!disabled ? 0 : -1}
          >
            <ChevronDown
              className={cx(styles.icons, {
                [styles.open]: open,
              })}
            />
          </button>
        </div>
      </div>
      {open && (
        <FloatingPortal root={containerRef}>
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: styles.popover,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                  display: 'flex',
                  flexDirection: 'column',
                },
              })}
            >
              { textSearch &&
                <div className={styles.searchTextWrapper}>
                  <TextField
                    value={searchText ? searchText : ''}
                    onChange={handleOnChangeSearchText}
                    startElement={
                      <SearchIcon
                        style={{
                          marginLeft: 'var(--padding-inner-sm)',
                          color: 'var(--color-grey-2)',
                          flex: '1 0 auto',
                        }}
                      />
                    }
                    endElement={
                      !!searchText && (
                        <button
                          onClick={clearSearchText}
                          className={styles.clearSearchText}
                        >
                          <LargeCloseIcon height={16} width={16} />
                        </button>
                      )
                    }
                    placeholder="Søg"
                    wrapperProps={{ className: styles.searchTextField }}
                    ref={searchTextFieldRef}
                  />
                </div>
              }
              <div className={styles.itemsList}>
                {searchText.length > 0 ? (
                  <FuzzySortItems
                    searchText={searchText}
                    options={options}
                    values={values}
                    getComboboxItemProps={getComboboxItemProps}
                    multiSelect={multiSelect}
                    activeIndex={activeIndex}
                  />
                ) : (
                  <ComboboxItems
                    topRanks={topRanked}
                    options={options}
                    values={values}
                    getComboboxItemProps={getComboboxItemProps}
                    multiSelect={multiSelect}
                    activeIndex={activeIndex}
                  />
                )}
              </div>
              <button
                onClick={onClear}
                disabled={values.length === 0}
                className={cx(styles.resetButton, {
                  [styles.disabled]: values.length === 0,
                })}
              >
                Nulstil
              </button>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};
