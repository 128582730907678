import Button from '@/atoms/Button';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogHeading,
  DialogTrigger,
} from '@/atoms/Dialog';
import dialogStyles from './SelectionDialog.module.scss';

type DialogProps = {
  header: string;
  trigger: React.ReactNode;
  disabled?: boolean;
  onClear: () => void;
  children: React.ReactNode;
};

export const GroupingFilterSelectionDialog = ({
  children,
  trigger,
  disabled,
  onClear,
  header,
}: DialogProps) => {
  return (
    <Dialog disabled={disabled}>
      <DialogTrigger>{trigger}</DialogTrigger>
      <DialogContent
        transition={{
          initial: { transform: 'translateX(50px)' },
        }}
        className={dialogStyles.dialog}
      >
        <DialogHeading hideCloseIcon hideBackIcon={false} clear={onClear}>
          {header}
        </DialogHeading>
        <DialogBody className={dialogStyles.dialogBody}>{children}</DialogBody>
        <DialogActions>
          <DialogClose>
            <Button>Gem og gå tilbage</Button>
          </DialogClose>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
