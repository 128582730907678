import React from 'react';
import Button from '@/atoms/Button';
import styles from './PartnerClickoutCard.module.scss';
import cx from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { getPartner } from 'api/frontpage';
import { trackPulseUIElementEvent } from '@smd/tracking';

import bilbasenImage from '../../public/images/bilbasenImage.jpeg';

interface IPartnerClickoutCard {
  image: string;
  logo?: string;
  title: string;
  text: string;
  buttonText: string | null;
  link: string;
  partnerDescription: string;
}

const handleTrackingPartnerClickOutClick = (partnerDescription: string) => {
  trackPulseUIElementEvent({
    elementName: 'PartnerClickout',
    eventDescription: partnerDescription,
  });
};

export const ClickoutCard = () => {
  const { data: partnerData } = useQuery(['partnerData'], () => getPartner());

  return (
    <section className={styles.cardContainer}>
      <SellOnBilbasen
        image={bilbasenImage.src}
        title="Sælg din bil på Bilbasen"
        text="Sæt din bil til salg for over 700.000 potentielle købere ugentligt."
        buttonText="Opret salgsannonce"
        link="/saelg-din-brugte-bil"
        partnerDescription="SellOnBilbasen"
      />
      {partnerData && (
        <PartnerClickout
          logo={partnerData.logo.hRef}
          image={partnerData.image.hRef}
          title={partnerData.heading}
          text={partnerData.textLines.join(' ')}
          buttonText={partnerData.action.caption}
          link={partnerData.action.hRef}
          partnerDescription="Solgt.Com"
        />
      )}
    </section>
  );
};

export const SellOnBilbasen = ({
  image,
  title,
  text,
  buttonText,
  link,
  partnerDescription,
}: IPartnerClickoutCard) => {
  return (
    <div className={cx(styles.card, styles.bilbasenCard)}>
      <div className={styles.imageWrapper}>
        {/*eslint-disable-next-line @next/next/no-img-element*/}
        <img src={image} alt={title} className={styles.bilbasenImage} />
      </div>
      <div className={styles.cardContent}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{text}</p>
        <Button
          variant="Secondary"
          className={styles.button}
          onClick={() => handleTrackingPartnerClickOutClick(partnerDescription)}
        >
          <a href={link} rel="noreferrer">
            {buttonText}
          </a>
        </Button>
      </div>
    </div>
  );
};

export const PartnerClickout = ({
  image,
  title,
  text,
  buttonText,
  logo,
  link,
  partnerDescription,
}: IPartnerClickoutCard) => {
  return (
    <div className={cx(styles.card, styles.partnerCard)}>
      <div className={styles.imageWrapper}>
        <div className={styles.imageCircle}>
          {/*eslint-disable-next-line @next/next/no-img-element*/}
          <img src={logo} alt={title} className={styles.partnerLogo} />
          {/*eslint-disable-next-line @next/next/no-img-element*/}
          <img src={image} alt={title} className={styles.partnerImage} />
        </div>
      </div>
      <div className={styles.cardContent}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{text}</p>
        <Button
          variant="Primary"
          className={cx(styles.button, styles.partnerButton)}
        >
          <a
            href={link}
            rel="noreferrer"
            onClick={() =>
              handleTrackingPartnerClickOutClick(partnerDescription)
            }
          >
            {buttonText}
          </a>
        </Button>
      </div>
    </div>
  );
};
