import React from 'react';
import { Car as GalleryCar, Gallery } from './Gallery';
import styles from './Galleries.module.scss';
import ChevronDown from '@/icons/ChevronDown';
import { useInfiniteQuery } from '@tanstack/react-query';
import { GetNewestCars, carToGalleryCar } from 'api/frontpage';

const defaultPageSize = 4;

export const NewestCars = () => {
  const {
    data: cars,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<
    Awaited<ReturnType<typeof GetNewestCars>>,
    Error,
    GalleryCar
  >(
    ['newestCars'],
    ({ pageParam = 1 }) => GetNewestCars(pageParam, defaultPageSize),
    {
      staleTime: 1000 * 60 * 10,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) =>
        allPages.length > 10 ? undefined : allPages.length + 1, // If we have more than 10 pages, we don't want to fetch more
      select: (data) => ({
        pageParams: data.pageParams,
        pages: data.pages.flat().map(carToGalleryCar),
      }),
    }
  );

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>SENESTE BILER PÅ BILBASEN</h2>
      <div className={styles.gallery}>
        <Gallery cars={cars?.pages.flat() ?? []} recommendationItem="Newest" />
      </div>
      <div className={styles.buttonContainer}>
        {isLoading ? (
          <div>Henter flere...</div>
        ) : hasNextPage ? (
          <button
            className={styles.button}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isLoading}
          >
            <span className={styles.buttonText}>
              Se flere seneste biler <ChevronDown />
            </span>
          </button>
        ) : null}
        {error && <p>Øv! Der skete en fejl - prøv igen senere</p>}
      </div>
    </section>
  );
};

export default NewestCars;
