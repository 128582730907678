import React from 'react';
import styles from './Blog.module.scss';
import { BlogPost, getBlogPosts } from 'api/frontpage';
import { trackPulseUIElementEvent } from '@smd/tracking';

type Props = {
  posts: BlogPost[];
};

export const Blog = ({ posts }: Props) => {
  const handleTrackingBlogClick = () => {
    trackPulseUIElementEvent({
      elementName: 'Blog',
      eventDescription: 'Blog',
    });
  };

  return (
    <>
      <h2 className={styles.headline}>Nyt fra bloggen</h2>
      <div className={styles.blogContainer}>
        {posts && posts.length > 0 && (
          <article className={styles.blogLargeWrapper}>
            <a
              href={posts[0].blogPostUri || '#'}
              onClick={handleTrackingBlogClick}
            >
              <div className={styles.imageWrapper}>
                <div
                  className={styles.blogLargeImage}
                  style={{
                    backgroundImage: `url(${posts[0].image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                ></div>
                <div className={styles.blogText}>
                  <h5 className={styles.categories}>{posts[0].category}</h5>
                  <p className={styles.description}>{posts[0].text}</p>
                </div>
              </div>
            </a>
          </article>
        )}

        <div className={styles.blogSmallWrapper}>
          {posts && posts.length > 1 && (
            <article>
              <a
                href={posts[1].blogPostUri || '#'}
                onClick={handleTrackingBlogClick}
              >
                <div className={styles.blogText}>
                  <h5 className={styles.categories}>{posts[1].category}</h5>
                  <p className={styles.description}>{posts[1].text}</p>
                </div>
                <div className={styles.imageWrapper}>
                  <div
                    className={styles.blogSmallImage}
                    style={{
                      backgroundImage: `url(${posts[1].image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                  />
                </div>
              </a>
            </article>
          )}

          {posts && posts.length > 2 && (
            <article>
              <a
                href={posts[2].blogPostUri || '#'}
                onClick={handleTrackingBlogClick}
              >
                <div className={styles.blogText}>
                  <h5 className={styles.categories}>{posts[2].category}</h5>
                  <p className={styles.description}>{posts[2].text}</p>
                </div>
                <div className={styles.imageWrapper}>
                  <div
                    className={styles.blogSmallImage}
                    style={{
                      backgroundImage: `url(${posts[2].image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                  />
                </div>
              </a>
            </article>
          )}
        </div>
      </div>
    </>
  );
};
