import React from 'react';
import styles from './GroupingFilter.module.scss';
import { SmdBasSrpModelsSelectedFilterValue } from 'types/api';
import { ReadOnlyGroupingFilterDropdownInput } from './special/ReadOnlyGroupingFilterDropdownInput';
import { GroupingFilterSelectionDialog } from './special/GroupingFilterSelectionDialog';
import { Platform, PlatformType } from '@/utils/Platform';
import { GroupingFilterDropdown } from './special/GroupingFilterDropdown';

type Props = {
  value: SmdBasSrpModelsSelectedFilterValue | undefined;
  placeholder: string;
  disabled?: boolean;
  onClear: () => void;
  getLabelValue: (
    value: string | number | SmdBasSrpModelsSelectedFilterValue
  ) => string;
  children: React.ReactNode;
};

export const GroupingFilter = ({
  value,
  placeholder,
  getLabelValue,
  disabled,
  onClear,
  children,
}: Props) => {
  let textContent = value ? getLabelValue(value) : placeholder;

  React.useEffect(() => {
    // once the filter is disabled, clear it;
    // to avoid an extra dispatch on mount, do it only if the textContent is not the placeholder
    if (!!onClear && disabled && textContent !== placeholder) {
      onClear();
    }
  }, [disabled, onClear, textContent, placeholder]);

  return (
    <>
      <Platform type={PlatformType.SSW} className={styles.halfWidth}>
        <GroupingFilterSelectionDialog
          header={placeholder}
          onClear={onClear}
          disabled={disabled}
          trigger={
            <div>
              <ReadOnlyGroupingFilterDropdownInput
                textContent={textContent}
                placeholder={placeholder}
                disabled={disabled}
              />
            </div>
          }
        >
          {children}
        </GroupingFilterSelectionDialog>
      </Platform>
      <Platform type={PlatformType.Desktop}>
        <GroupingFilterDropdown
          textContent={textContent}
          placeholder={placeholder}
          disabled={disabled}
          onClear={onClear}
        >
          {children}
        </GroupingFilterDropdown>
      </Platform>
    </>
  );
};
