import { PageVariant, Platform, PlatformType } from '@/utils/Platform';
import styles from './DropdownFrontpage.module.scss';
import { Dropdown, IDropdownProps } from '@/atoms/Dropdown';

type Props = Omit<IDropdownProps, 'variant'>;
export const DropdownFrontpage = ({
  label,
  values,
  options,
  onChange,
  onClear,
  placeholder,
  icon,
  multiSelect,
  disabled,
  containerRef,
}: Props) => {
  return (
    <>
      <Platform type={PlatformType.SSW} className={styles.fullWidth}>
        <Dropdown
            options={options}
            onChange={onChange}
            values={values}
            label={label}
            placeholder={placeholder}
            icon={icon}
            onClear={onClear}
            multiSelect={multiSelect}
            disabled={disabled}
            containerRef={containerRef}
            variant={PageVariant.Frontpage}
        />
      </Platform>
      <Platform type={PlatformType.Desktop} className={styles.desktopWrapper}>
        <Dropdown
            options={options}
            onChange={onChange}
            values={values}
            label={label}
            placeholder={placeholder}
            icon={icon}
            onClear={onClear}
            multiSelect={multiSelect}
            disabled={disabled}
            containerRef={containerRef}
            variant={PageVariant.Frontpage}
        />
      </Platform>
    </>
  );
};
