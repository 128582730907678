import React from 'react';
import { Gallery } from './Gallery';
import styles from './Galleries.module.scss';
import ChevronDown from '@/icons/ChevronDown';
import { useInfiniteQuery } from '@tanstack/react-query';
import { GetXtraCars, KlikXtraCarToGalleryCar } from 'api/frontpage';

const defaultPageSize = 12;

export const KlikXtraCars = () => {
  const {
    data: cars,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['klikXtraCars'],
    queryFn: ({ pageParam = 1 }) => GetXtraCars(pageParam, defaultPageSize),
    staleTime: Infinity,
    getNextPageParam: (lastPage, allPages) =>
      allPages.length > 10 ? undefined : allPages.length, // If we have more than 10 pages, we don't want to fetch more
    select: (data) => ({
      pageParams: data.pageParams,
      pages: data.pages.flat().map(KlikXtraCarToGalleryCar),
    }),
  });

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>BRUGTE BILER TIL SALG PÅ BILBASEN</h2>
      <div className={styles.gallery}>
        <Gallery cars={cars?.pages ?? []} recommendationItem="KlikXtra" />
      </div>
      <div className={styles.buttonContainer}>
        {isLoading ? (
          <div>Henter flere...</div>
        ) : hasNextPage ? (
          <button
            className={styles.button}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isLoading}
          >
            <span className={styles.buttonText}>
              Se flere biler <ChevronDown />
            </span>
          </button>
        ) : null}
        {error !== null && error !== undefined && (
          <p>Øv! Der skete en fejl - prøv igen senere</p>
        )}
      </div>
    </section>
  );
};

export default KlikXtraCars;
