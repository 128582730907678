import React from 'react';
import styles from './GroupingFilterDropdown.module.scss';
import { useFloatingList } from '@/hooks/useFloatingList';
import cx from 'classnames';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import ChevronDown from '@/icons/ChevronDown';

type Props = {
  textContent: string;
  placeholder: string;
  disabled?: boolean;
  onClear: () => void;
  children: React.ReactNode;
};

export const GroupingFilterDropdown = ({
  textContent,
  placeholder,
  disabled,
  onClear,
  children,
}: Props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const getMaxHeight = React.useCallback(
    (availableHeight: number) => '400px',
    []
  );

  const {
    x,
    y,
    strategy,
    refs,
    context,
    getReferenceProps,
    getFloatingProps,
    setOpen,
    open,
  } = useFloatingList(
    undefined,
    false,
    getMaxHeight,
    undefined,
    'bottom-start',
    '320px',
    false
  );

  return (
    <div ref={containerRef}>
      <div
        {...getReferenceProps({
          ref: refs.setReference,
          className: cx(styles.wrapper, styles.selectionCombobox, {
            [styles.disabled]: disabled,
          }),
          onClick: () => (!disabled ? setOpen((prev) => !prev) : undefined),
          tabIndex: !disabled ? 0 : undefined,
          ['aria-disabled']: disabled,
        })}
      >
        <div
          className={cx(styles.content, {
            // [styles.placeholder]: !hasValue,
          })}
        >
          {textContent}
        </div>
        <div className={styles.actions}>
          <button
            aria-label="Åbn dropdown"
            aria-disabled={disabled}
            tabIndex={!disabled ? 0 : -1}
          >
            <ChevronDown
              className={cx(styles.icons, {
                [styles.open]: open,
              })}
            />
          </button>
        </div>
      </div>
      {open && (
        <FloatingPortal root={containerRef}>
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: styles.popover,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                  display: 'flex',
                  flexDirection: 'column',
                },
              })}
            >
              <div className={styles.filters}>{children}</div>
              <button
                onClick={onClear}
                disabled={textContent === placeholder}
                className={cx(styles.resetButton, {
                  [styles.disabled]: textContent === placeholder,
                })}
              >
                Nulstil
              </button>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};
