import React from 'react';
import {
  SmdBasSrpModelsSearchInfo,
  SmdBasSrpModelsSearchRequest,
} from 'types/api';
import { FilterFactory } from './filters/FilterFactory';
import { useFrontpageSearch } from '@/hooks/useFrontpageSearch';
import Button from '@/atoms/Button';
import styles from './SearchFilters.module.scss';
import cx from 'classnames';
import { formatNumber } from '@/utils';
import {trackPulseUIElementEvent} from "@smd/tracking";
import {
    AmplitudeExperimentsContext,
    makeExperimentObject,
    pulseExperimentObject
} from "@/utils/amplitudeExperiments";

type SearchFiltersProps = {
  initialSearchRequest: SmdBasSrpModelsSearchRequest;
  metaTexts: SmdBasSrpModelsSearchInfo;
};

export const SearchFilters = ({
  initialSearchRequest,
  metaTexts,
}: SearchFiltersProps) => {
  const { data, getValue, setValue, toggleValue, clear, getLabelValue } =
    useFrontpageSearch(initialSearchRequest);
  const experiments = React.useContext(AmplitudeExperimentsContext);

  return (
    <div className={cx('flexWrapCenter', styles.filtersOuterWrapper)}>
      <div className={cx('flexWrapCenter', styles.filtersInnerWrapper)}>
        <FilterFactory
          data={data?.filterOptions ?? []}
          metaTexts={metaTexts}
          getValue={getValue}
          setValue={setValue}
          toggleValue={toggleValue}
          clearValue={clear}
          getLabelValue={getLabelValue}
        />
        <Button
            as="a"
            href={data?.searchUrl!}
            className={styles.searchBtn}
            onClick={() => {
                trackPulseUIElementEvent({
                    elementName: 'FrontpageSearch',
                    eventDescription: 'search',
                    experiments: pulseExperimentObject(experiments)
                });
            }}
        >
          {data?.abundance
            ? `Vis ${formatNumber(data?.abundance)} biler`
            : `Vis 0 biler`}
        </Button>
        <div className={styles.otherBtns}>
          <Button
            variant="DarkLabel"
            onClick={() => {
              trackPulseUIElementEvent({
                elementName: 'FrontpageSearch',
                eventDescription: 'reset',
                experiments: pulseExperimentObject(experiments)
              });
              clear();
            }}
          >
            Nulstil
          </Button>
          <Button
            as="a"
            onClick={() => {
              trackPulseUIElementEvent({
                  elementName: 'FrontpageSearch',
                  eventDescription: 'extended-search',
                  experiments: pulseExperimentObject(experiments)
              });
            }}
            variant="DarkLabel"
            href={`${data?.searchUrl!}#open-filters`}
          >
            Udvidet søgning
          </Button>
        </div>
      </div>
    </div>
  );
};

SearchFilters.displayName = 'SearchFilters';
