import styles from './ReadOnlyModelDropdownInput.module.scss';
import React from 'react';
import {
  SmdBasSrpModelsFilterOptionValue,
  SmdBasSrpModelsSelectedFilterValue,
} from 'types/api';
import cx from 'classnames';
import ChevronDown from '@/icons/ChevronDown';
import { flattenOptionValues } from '@/utils';

type Props = {
  values?: SmdBasSrpModelsSelectedFilterValue[];
  options: Array<SmdBasSrpModelsFilterOptionValue & { level?: number }>;
  placeholder?: string;
  disabled?: boolean;
  'data-e2e'?: string;
};

export const ReadOnlyModelDropdownInput = ({
  placeholder,
  disabled,
  values,
  options,
  'data-e2e': datae2e,
}: Props) => {
  const allOptionValues = React.useMemo(() => {
    return options.flatMap((o) => flattenOptionValues(o.optionValues!));
  }, [options]);

  const selectedOptions = React.useMemo(() => {
    return values
      ?.flatMap((v) => v.values)
      .map((value) => allOptionValues.find((o) => o.value === value))
      .filter(Boolean);
  }, [values, allOptionValues]);

  let textContent = 'Model';
  if (selectedOptions && selectedOptions?.length > 0) {
    if (selectedOptions.length === allOptionValues.length) {
      textContent = 'Alle';
    } else {
      textContent = selectedOptions?.map((o) => o?.name).join(', ');
    }
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        styles.fullWidth,
        styles.selectionCombobox,
        {
          [styles.disabled]: disabled,
        }
      )}
      tabIndex={!disabled ? 0 : -1}
      aria-disabled={disabled}
    >
      <div
        className={cx(styles.content, {
          // [styles.placeholder]: !hasValue,
        })}
      >
        {textContent || placeholder || 'Vælg'}
      </div>
      <div className={styles.actions}>
        <button
          aria-label="Åbn dropdown"
          aria-disabled={disabled}
          tabIndex={!disabled ? 0 : -1}
        >
          <ChevronDown
            className={cx(styles.icons, {
              [styles.open]: false,
            })}
          />
        </button>
      </div>
    </div>
  );
};
