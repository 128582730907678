/* eslint-disable @next/next/no-img-element */
import Button from '@/atoms/Button';
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeading,
  DialogBody,
  DialogActions,
  DialogClose,
} from '@/atoms/Dialog';
import dialogStyles from './SelectionDialog.module.scss';
import styles from './AllModelsSelectionDialog.module.scss';
import React, { Fragment } from 'react';
import fuzzysort from 'fuzzysort';
import TextField from '@/atoms/TextField';
import SearchIcon from '@/icons/SearchIcon';
import {
  SmdBasSrpModelsDependantFilterOptionValue,
  SmdBasSrpModelsFilterOptionValue,
  SmdBasSrpModelsSelectedFilterValue,
} from 'types/api';
import LargeCloseIcon from '@/icons/LargeCloseIcon';
import { flattenOptionValues } from '@/utils';

// To be able to style the highlight of a searchText within the filtered options
// and to avoid using dangerouslySetInnerHTML: https://github.com/farzher/fuzzysort/issues/66#issuecomment-602167743
const highlight = (r: Fuzzysort.Result | undefined, f: any, s = '\x01') =>
  fuzzysort
    .highlight(r, s, s)!
    .split(s)
    .map((s, i) =>
      i % 2
        ? f(s, i)
        : s.replace(/^\s+|\s+$/g, (match) => {
            // for the remaining non-highlighted text, make sure to also render any potential spaces at the start & end, in the html
            return '\xa0'.repeat(match.length);
          })
    );

type ComboboxItemsProps = {
  options: SmdBasSrpModelsDependantFilterOptionValue[];
  multiSelect?: boolean;
  values: SmdBasSrpModelsSelectedFilterValue[];
  onChange: (value: any) => void;
};

type FuzzySortItemsProps = ComboboxItemsProps & { searchText: string };

const getFuzzySortItem = (
  multiSelect: ComboboxItemsProps['multiSelect'],
  values: ComboboxItemsProps['values'],
  option: Fuzzysort.KeysResult<SmdBasSrpModelsFilterOptionValue>,
  onChange: (value: any) => void,
  dependantOptionParent?: SmdBasSrpModelsDependantFilterOptionValue
) => (
  <div style={{ fontWeight: 'bold' }}>
    <label className="label">
      {multiSelect ? (
        <>
          <input
            type="checkbox"
            name={option.obj.value + ''}
            checked={
              values.flatMap((v) => v.values)?.includes(option.obj.value!) ??
              false
            }
            onChange={() =>
              onChange({
                parent: {
                  key: dependantOptionParent!.parentKey,
                  value: dependantOptionParent!.parentValue,
                },
                value: option.obj.value!,
              })
            }
            readOnly
          />
          <span>
            {highlight(option[0], (m: any, i: any) => (
              <span style={{ fontWeight: 'normal' }} key={i}>
                {m}
              </span>
            ))}
          </span>
        </>
      ) : (
        <span>
          {highlight(option[0], (m: any, i: any) => (
            <span style={{ fontWeight: 'normal' }} key={i}>
              {m}
            </span>
          ))}
        </span>
      )}
    </label>
  </div>
);

const getComboboxItems =
  (
    multiSelect: ComboboxItemsProps['multiSelect'],
    values: ComboboxItemsProps['values'],
    onChange: (value: any) => void,
    optionParent: SmdBasSrpModelsDependantFilterOptionValue
  ) =>
  // eslint-disable-next-line react/display-name
  (
    option: SmdBasSrpModelsFilterOptionValue & {
      image?: string;
      level?: number;
    },
    index: number
  ) =>
    (
      <div style={{ paddingLeft: (option.level ?? 0) * 16 }}>
        <label className="label">
          {multiSelect ? (
            <>
              <input
                type="checkbox"
                name={option.value + ''}
                checked={
                  values.flatMap((v) => v.values)?.includes(option.value!) ??
                  false
                }
                onChange={() =>
                  onChange({
                    parent: {
                      key: optionParent!.parentKey,
                      value: optionParent!.parentValue,
                    },
                    value: option.value!,
                  })
                }
                readOnly
              />
              {option.name}
            </>
          ) : (
            option.name
          )}
        </label>
      </div>
    );

export const FuzzySortItems = ({
  options,
  searchText,
  multiSelect,
  onChange,
  values,
}: FuzzySortItemsProps) => {
  const results = React.useMemo(() => {
    const allOptionValues = options.flatMap((o) =>
      flattenOptionValues(o.optionValues!)
    );
    return fuzzysort.go(searchText, allOptionValues, {
      limit: 10,
      keys: ['name'],
    });
  }, [searchText, options]);

  if (results.length === 0) {
    return (
      <div className={styles.noResults}>
        Der blev ikke fundet nogen resultater.
      </div>
    );
  }

  const getDependantOptionParent = (
    option: SmdBasSrpModelsFilterOptionValue
  ) => {
    const optionParent = options.find(
      (o) =>
        !!flattenOptionValues(o.optionValues!)?.find(
          (ov) => ov.value === option.value
        )
    );
    return optionParent;
  };
  return (
    <>
      {results.map((option, index) => (
        <Fragment key={`${option.obj.value}${index}`}>
          {getFuzzySortItem(
            multiSelect,
            values,
            option,
            onChange,
            getDependantOptionParent(option.obj)
          )}
        </Fragment>
      ))}
    </>
  );
};

const ComboboxItems = ({
  options,
  multiSelect,
  values,
  onChange,
}: ComboboxItemsProps) => {
  return (
    <>
      {options.map((option, index) => (
        <Fragment key={`${option.parentValue}${index}`}>
          <div className={styles.sectionTitle}>{option.parentValue}</div>
          {flattenOptionValues(option.optionValues!).map(
            getComboboxItems(multiSelect, values, onChange, option)
          )}
        </Fragment>
      ))}
    </>
  );
};

type DialogProps = {
  children: React.ReactNode;
  values: SmdBasSrpModelsSelectedFilterValue[];
  options: Array<
    SmdBasSrpModelsDependantFilterOptionValue & { level?: number }
  >;
  onChange: (value: any) => void;
  onClear?: () => void;
  multiSelect?: boolean;
  disabled?: boolean;
};

export const AllModelsSelectionDialog = ({
  children,
  values,
  options,
  onChange,
  onClear,
  multiSelect = true,
  disabled,
}: DialogProps) => {
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = React.useState('');

  const handleOnChangeSearchText = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  return (
    <Dialog disabled={disabled}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent
        transition={{
          initial: { transform: 'translateX(50px)' },
        }}
        className={dialogStyles.dialog}
      >
        <DialogHeading hideCloseIcon hideBackIcon={false} clear={onClear}>
          Modeller
        </DialogHeading>
        <DialogBody className={dialogStyles.dialogBody} ref={bodyRef}>
          <TextField
            value={searchText ? searchText : ''}
            onChange={handleOnChangeSearchText}
            startElement={
              <SearchIcon
                style={{
                  marginLeft: 'var(--padding-inner-sm)',
                  color: 'var(--color-grey-2)',
                }}
              />
            }
            endElement={
              !!searchText && (
                <button
                  onClick={clearSearchText}
                  className={styles.resetButton}
                >
                  <LargeCloseIcon height={16} width={16} />
                </button>
              )
            }
            placeholder="Søg på modeller..."
          />
          <div className={styles.models}>
            {searchText.length > 0 ? (
              <FuzzySortItems
                searchText={searchText}
                options={options}
                values={values}
                multiSelect={multiSelect}
                onChange={onChange}
              />
            ) : (
              <ComboboxItems
                options={options}
                values={values}
                multiSelect={multiSelect}
                onChange={onChange}
              />
            )}
          </div>
        </DialogBody>
        <DialogActions>
          <DialogClose>
            <Button>Gem og gå tilbage</Button>
          </DialogClose>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
