import electric from '../../../public/images/el.jpg';
import privateLeasing from '../../../public/images/leasing.jpg';
import businessLeasing from '../../../public/images/van.jpg';
import family from '../../../public/images/family.jpg';
import hybrid from '../../../public/images/hybrid.jpg';
import micro from '../../../public/images/micro.jpg';

export const images: Record<string, unknown> = {
  electric,
  privateLeasing,
  businessLeasing,
  family,
  hybrid,
  micro,
};
