import React, { useState } from 'react';
import { newsletterSignUp } from 'api/frontpage';
import styles from './NewsletterSubscription.module.scss';
import { useMutation } from '@tanstack/react-query';
import cx from 'classnames';

const emailRegex =
  /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

export const NewsletterSubscription = () => {
  const [email, setEmail] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [submitPressed, setSubmitPressed] = useState<boolean>(false);

  const {
    mutate: subscribe,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(newsletterSignUp, {
    onSuccess: () => {
      setEmail('');
      setSubmitPressed(false);
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitPressed(true);
    const isValid = emailRegex.test(email);
    setIsValidEmail(isValid);

    if (isValid) {
      subscribe(email);
    }
  };

  return (
    <div className={styles.container}>
      {isValidEmail && isSuccess ? (
        <div className={styles.successMessage}>
          <h4>Vi har modtaget din tilmelding!</h4>{' '}
          <p>
            Du modtager en email fra Bilbasen om kort tid. Den indeholder et
            link, som du skal klikke på for at bekræfte din tilmelding til
            Bilbasens nyhedsbrev.
          </p>
        </div>
      ) : (
        <form className={styles.form} onSubmit={handleSubmit}>
          <>
            {isError && error instanceof Error && (
              <div className={styles.errorMessageWrapper}>
                <span className={styles.errorMessage}>
                  Der skete en uventet fejl. Prøv igen.
                </span>
              </div>
            )}
            {!isValidEmail && submitPressed && (
              <div className={styles.errorMessageWrapper}>
                <span className={styles.errorMessage}>
                  Tjek den indtastede email og prøv igen.
                </span>
              </div>
            )}
          </>
          <div className={styles.wrapper}>
            <p className={styles.text}>
              Få tips og guides i din indbakke med Bilbasens nyhedsbrev
            </p>
            <div className={styles.inputButtonWrapper}>
              <input
                type="email"
                className={cx(styles.formInput, {
                  [styles.errorBorder]:
                    (!isValidEmail && submitPressed) || isError,
                })}
                placeholder="Skriv email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!isValidEmail || submitPressed || isError) {
                    setIsValidEmail(true);
                    setSubmitPressed(false);
                  }
                }}
              />

              <button
                type="submit"
                disabled={isLoading}
                className={styles.submitButton}
              >
                Tilmeld nyhedsbrev
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
