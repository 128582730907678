import React from 'react';
import {
  SmdBasSrpModelsFilterOption,
  SmdBasSrpModelsSelectedFilterValue,
} from 'types/api';
import styles from './ModelFilterWithCombobox.module.scss';
import { ReadOnlyModelDropdownInput } from './ReadOnlyModelDropdownInput';
import { ModelSelectionCombobox } from './ModelSelectionCombobox';
import { AllModelsSelectionDialog } from './AllModelsSelectionDialog';
import { Platform, PlatformType } from '@/utils/Platform';

type Props = {
  filter: SmdBasSrpModelsFilterOption;
  values?: SmdBasSrpModelsSelectedFilterValue[];
  toggleValue: (key: string, value: any) => void;
  onClear: () => void;
  disabled?: boolean;
};

export const ModelFilterWithCombobox = ({
  filter,
  values,
  toggleValue,
  onClear,
  disabled,
}: Props) => {
  return (
    <>
      <Platform type={PlatformType.SSW} className={styles.fullWidth}>
        <AllModelsSelectionDialog
          values={values ?? []}
          options={filter.dependantOptionValues ?? []}
          onChange={(value) => toggleValue(filter.key!, value)}
          onClear={onClear}
          disabled={disabled}
        >
          <div className={styles.fullWidth}>
            <ReadOnlyModelDropdownInput
              values={values ?? []}
              options={filter.dependantOptionValues ?? []}
              placeholder="Model"
              disabled={disabled}
            />
          </div>
        </AllModelsSelectionDialog>
      </Platform>
      <Platform type={PlatformType.Desktop}>
        <ModelSelectionCombobox
          values={values ?? []}
          onChange={(value) => toggleValue(filter.key!, value)}
          onClear={onClear}
          options={filter.dependantOptionValues ?? []}
          disabled={disabled}
        />
      </Platform>
    </>
  );
};
