import React from 'react';
import styles from './CategoryImage.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { trackPulseUIElementEvent } from '@smd/tracking';

interface ICategoryImageProps {
  id: string;
  href: string;
  imageSrc: string;
  imageTitle: string;
}

export const CategoryImage = ({
  id,
  href,
  imageSrc,
  imageTitle,
}: ICategoryImageProps) => {
  const handleTrackingNeedBasedSearchOnClick = () => {
    trackPulseUIElementEvent({
      elementName: 'NeedBasedSearch',
      eventDescription: id,
    });
  };

  return (
    <li className={styles.carSearchList}>
      <Link id={id} href={href} onClick={handleTrackingNeedBasedSearchOnClick}>
        <div className={styles.imageOverlay}>
          <Image
            className={styles.categoryImage}
            src={imageSrc}
            alt=""
            width={140}
            height={186}
            unoptimized
          />
          <p className={styles.imageOverlayTitle}>{imageTitle}</p>
        </div>
      </Link>
    </li>
  );
};
