import React, { Fragment } from 'react';
import {
  SmdBasSrpModelsFilterOption,
  SmdBasSrpModelsSearchInfo,
  SmdBasSrpModelsSelectedFilterValue,
} from '../../../types/api';
import { Toggle } from '@/atoms/Toggle';
import { FilterDescription } from '@/atoms/FilterDescription';
import { RangeFilter } from '@/molecules/filters/RangeFilter';
import { FilterWithCombobox } from './FilterWithCombobox';
import { ModelFilterWithCombobox } from '@/molecules/filters/special/ModelFilterWithCombobox';
import { GroupingFilter } from '@/molecules/filters/GroupingFilter';
import { DropdownFrontpage } from "./DropdownFrontpage";

export type FilterFactoryProps = {
  data: SmdBasSrpModelsFilterOption[];
  metaTexts: SmdBasSrpModelsSearchInfo;
  getValue: (key: string) => any;
  setValue: (key: string, value: any, id?: any) => void;
  toggleValue: (key: string, value: any) => void;
  clearValue: (key: string) => void;
  getLabelValue: (
    key: string,
    value: string | number | SmdBasSrpModelsSelectedFilterValue
  ) => string;
  level?: number;
  containerRef?: React.RefObject<HTMLDivElement | null>;
};

export const FilterFactory = ({
  data,
  metaTexts,
  getValue,
  setValue,
  toggleValue,
  clearValue,
  getLabelValue,
  level = 0,
  containerRef,
}: FilterFactoryProps) => {
  const getDependentOnValues = (keys: string[]) => {
    return keys.map((key) => getValue(key)).filter(Boolean);
  };
  return (
    <>
      {data.map((filter, index) => {
        const popover = metaTexts.filterPopOvers?.[filter.key!];

        switch (filter.type) {
          case 'Grouping': {
            if (filter.key) {
              const filterKey =
                filter.key === 'PriceTypeGroup'
                  ? filter.filterOptions![1].key!
                  : filter.filterOptions![0].key!; // key of the filter inside the grouping filter
              const value = getValue(filterKey) as
                | SmdBasSrpModelsSelectedFilterValue
                | undefined;

              return (
                <GroupingFilter
                  key={`${filter.type}${index}`}
                  placeholder={filter.title ?? 'Vælg...'}
                  value={value}
                  getLabelValue={(value) => getLabelValue(filterKey, value)}
                  onClear={() => filterKey && clearValue(filterKey)}
                >
                  <FilterFactory
                    data={filter.filterOptions!}
                    metaTexts={metaTexts}
                    getValue={getValue}
                    setValue={setValue}
                    toggleValue={toggleValue}
                    clearValue={clearValue}
                    getLabelValue={getLabelValue}
                    level={level + 1}
                    containerRef={containerRef}
                  />
                </GroupingFilter>
              );
            }

            return (
              <FilterFactory
                key={`${filter.type}${index}`}
                data={filter.filterOptions!}
                metaTexts={metaTexts}
                getValue={getValue}
                setValue={setValue}
                toggleValue={toggleValue}
                clearValue={clearValue}
                getLabelValue={getLabelValue}
                level={level + 1}
                containerRef={containerRef}
              />
            );
          }

          case 'SingleSelection':
            const singleValue = [getValue(filter.key!)].filter(
              Boolean
            ) as Array<string | number>;

            if (filter.renderType === 'Toggle') {
              return (
                <FilterDescription
                  data-e2e={`toggle-${filter.key}`}
                  key={filter.key}
                  title={filter.title ?? undefined}
                  subtitle={filter.subtitle ?? undefined}
                  popover={popover}
                  className="spanAllColumns"
                >
                  <Toggle
                    key={filter.key}
                    values={singleValue}
                    options={filter.optionValues!}
                    onClick={(val) => setValue(filter.key!, val)}
                  />
                </FilterDescription>
              );
            }
            return (
              <DropdownFrontpage
                options={filter.optionValues!}
                values={singleValue}
                multiSelect={false}
                onChange={(val) => setValue(filter.key!, val)}
                onClear={() => filter.key && clearValue(filter.key)}
                placeholder={filter.placeholdertext ?? 'Alle'}
                disabled={
                  !!filter.dependentOn &&
                  getDependentOnValues(filter.dependentOn).length === 0
                }
                containerRef={containerRef}
              />
            );

          case 'MultiSelection':
            const selectedMulti = getValue(
              filter.key!
            ) as SmdBasSrpModelsSelectedFilterValue[];
            const selectedMultiValues =
              (selectedMulti?.map((v) => v.value).filter(Boolean) as Array<
                string | number
              >) ?? [];

            if (filter.renderType === 'Make') {
              return (
                <FilterWithCombobox
                  key={`${filter.type}${index}`}
                  filter={filter}
                  values={selectedMultiValues}
                  toggleValue={toggleValue}
                  metaTexts={metaTexts}
                  onClear={() => filter.key && clearValue(filter.key)}
                />
              );
            }

            if (filter.renderType === 'Checkbox') {
              return (
                  <FilterWithCombobox
                      key={`${filter.type}${index}`}
                      filter={filter}
                      values={selectedMultiValues}
                      toggleValue={toggleValue}
                      metaTexts={metaTexts}
                      onClear={() => filter.key && clearValue(filter.key)}
                  />
              );
            }

            return <Fragment key={`${filter.type}${index}`}></Fragment>;

          case 'NestedMultiSelection':
            const selectedNestedMulti = getValue(
              filter.key!
            ) as SmdBasSrpModelsSelectedFilterValue[];
            return (
              <ModelFilterWithCombobox
                key={`${filter.type}${index}`}
                filter={filter}
                values={selectedNestedMulti}
                toggleValue={toggleValue}
                onClear={() => filter.key && clearValue(filter.key)}
                disabled={
                  filter.key === 'Model' &&
                  (!filter.dependantOptionValues ||
                    filter.dependantOptionValues?.length === 0 ||
                    !getValue('Make')) // If no make is selected, don't show models
                }
              />
            );
          case 'Range': {
            const values = getValue(filter.key!) as
              | SmdBasSrpModelsSelectedFilterValue
              | undefined;

            return (
              <FilterDescription
                title={filter.title ?? undefined}
                subtitle={filter.subtitle ?? undefined}
                key={filter.key}
                className="spanAllColumns"
                popover={popover}
              >
                <>
                  <RangeFilter
                    id={filter.key!}
                    min={filter.minValue!}
                    max={filter.maxValue!}
                    step={filter.step!}
                    value={{
                      from:
                        !!values && !!values.fromValue
                          ? values.fromValue
                          : undefined,
                      to:
                        !!values && !!values.toValue
                          ? values.toValue
                          : undefined,
                    }}
                    fromLabel={filter.placeholderTextFrom ?? 'Fra'}
                    toLabel={filter.placeholderTextTo ?? 'Til'}
                    onChange={(from, to) => {
                      setValue(filter.key!, { fromValue: from, toValue: to });
                    }}
                    unit={filter.unit}
                  />
                </>
              </FilterDescription>
            );
          }
          default:
            return <Fragment key={`${filter.type}${index}`}></Fragment>;
        }
      })}
    </>
  );
};
