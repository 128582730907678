import styles from './ReadOnlyMakeDropdownInput.module.scss';
import React from 'react';
import { SmdBasSrpModelsFilterOptionValue } from '../../../types/api';
import cx from 'classnames';
import ChevronDown from '@/icons/ChevronDown';

type Props = {
  values?: Array<string | number>;
  options: Array<SmdBasSrpModelsFilterOptionValue & { level?: number }>;
  placeholder?: string;
  'data-e2e'?: string;
};

export const ReadOnlyMakeDropdownInput = ({
  placeholder,
  values,
  options,
  'data-e2e': datae2e,
}: Props) => {
  const selectedOptions = React.useMemo(
    () =>
      values
        ?.map((value) => options.find((o) => o.value === value))
        .filter(Boolean),
    [values, options]
  );

  let textContent = '';
  if (selectedOptions && selectedOptions?.length > 0) {
    if (selectedOptions.length === options.length) {
      textContent = 'Alle';
    } else {
      textContent = selectedOptions?.map((o) => o?.name).join(', ');
    }
  }
  return (
    <div
      className={cx(styles.wrapper, styles.fullWidth, styles.selectionCombobox)}
      tabIndex={0}
    >
      <div
        className={cx(styles.content, {
          // [styles.placeholder]: !hasValue,
        })}
      >
        {textContent || placeholder || 'Vælg'}
      </div>
      <div className={styles.actions}>
        <button aria-label="Åbn dropdown" tabIndex={0}>
          <ChevronDown
            className={cx(styles.icons, {
              [styles.open]: false,
            })}
          />
        </button>
      </div>
    </div>
  );
};
