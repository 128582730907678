import React from 'react';
import {
  SmdBasSrpModelsFilterOption,
  SmdBasSrpModelsSearchInfo,
} from '../../../types/api';
import styles from './FilterWithCombobox.module.scss';
import { ReadOnlyMakeDropdownInput } from './ReadOnlyMakeDropdownInput';
import { Platform, PlatformType } from '@/utils/Platform';
import { SelectionCombobox } from './SelectionCombobox';
import { SelectionDialog } from '@/molecules/filters/special/SelectionDialog';
import cx from 'classnames';

type Props = {
  filter: SmdBasSrpModelsFilterOption;
  values?: Array<string | number>;
  toggleValue: (key: string, value: any) => void;
  metaTexts?: SmdBasSrpModelsSearchInfo;
  onClear: () => void;
};

export const FilterWithCombobox = ({
  filter,
  values,
  toggleValue,
  metaTexts,
  onClear,
}: Props) => {
  const topRanked = React.useMemo(
    () =>
      (filter.optionValues ?? [])
        .filter((f) => typeof f.rank === 'number')
        .map((f) => ({
          ...f,
          image:
            metaTexts?.optionValueImageUrls?.[
              `${filter.key}${f.value!.toLowerCase()}`
            ] ?? '',
        }))
        .sort((a, b) => a.rank! - b.rank!),
    [filter.key, filter.optionValues, metaTexts]
  );
  return (
    <>
      <Platform type={PlatformType.SSW} className={cx({
          [styles.fullWidth]: filter.key === 'Make',
          [styles.halfWidth]: filter.key !== 'Make',
      })}>
        <SelectionDialog
          filter={filter}
          values={values ?? []}
          toggleValue={toggleValue}
          topRanked={topRanked}
          onClear={onClear}
          alphabetNavigation={filter.key === 'Make'}
          textSearch={filter.key === 'Make'}
        >
          <div>
            <ReadOnlyMakeDropdownInput
              values={values ?? []}
              options={filter.optionValues ?? []}
              placeholder={filter.title ?? ''}
            />
          </div>
        </SelectionDialog>
      </Platform>
      <Platform type={PlatformType.Desktop}>
        <SelectionCombobox
          filter={filter}
          values={values ?? []}
          onChange={(value) => toggleValue(filter.key!, value)}
          onClear={onClear}
          options={filter.optionValues ?? []}
          topRanked={topRanked}
          textSearch={filter.key === 'Make'}
        />
      </Platform>
    </>
  );
};
