import React from 'react';
import styles from './CategoryList.module.scss';

interface ICategoryListProps {
  categoryTitle: string;
  children: React.ReactNode;
}

export const CategoryList = ({
  categoryTitle,
  children,
}: ICategoryListProps) => {
  return (
    <>
      <h4 className={styles.categoryTitle}>{categoryTitle}</h4>
      <ul className={styles.categoryList}>{children}</ul>
    </>
  );
};
