import Head from 'next/head';
import {
  SmdBasSrpModelsSearchInfo,
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpWebApiModelsSeoData,
} from 'types/api';
import { CategoryList } from 'components/frontpage/CategoryList';
import { CategoryImage } from 'components/frontpage/CategoryImage';
import { ClickoutCard } from 'components/frontpage/PartnerClickoutCard';
import ExtraPromotedCars from 'components/frontpage/ExtraPromotedCars';
import { PopularCars } from 'components/frontpage/PopularCars';
import { LinkGroup } from 'components/frontpage/LinkGroup';
import NewestCars from 'components/frontpage/NewestCars';
import { QueryClient, dehydrate, type DehydratedState } from '@tanstack/react-query';
import { FilledContext } from 'react-helmet-async';
import { GetServerSideProps } from 'next';
import {getMetaTexts, UserDetailsDto} from 'api/search';
import {
  BlogPost,
  GetKlikXtraCars,
  getAbundance,
  getBlogPosts,
  getDatalayer,
  getSearchFiltersByUrl,
  getUserDetails,
} from 'api/frontpage';
import KlikXtraCars from 'components/frontpage/KlikXtraCars';
import { NewsletterSubscription } from 'components/frontpage/NewsletterSubscription';
import { Blog } from 'components/frontpage/Blog';
import categoryData from '../components/frontpage/data/categoryData.json';
import { images } from '../components/frontpage/data/categoryImages';
import { formatNumber } from '@/utils';
import { SearchFilters } from 'components/frontpage/SearchFilters';
import type { MeridianDataLayerValue } from '@smd/datalayer-typings';
import {IncomingHttpHeaders} from "http";
import { fetchAds } from 'api/common';
import * as Ads from '@smd/advertising';
import { useMedia } from 'react-use';
import {
  AmplitudeExperimentsContext,
  ExperimentVariant,
  getExperimentVariants,
  PULSE_DATA_COOKIE_NAME,
} from "@/utils/amplitudeExperiments";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";

export namespace Frontpage {
  export type Props = {
    blogPosts: BlogPost[];
    initialSearchRequest: SmdBasSrpModelsSearchRequest;
    metaTexts: SmdBasSrpModelsSearchInfo;
    totalAbundance: number;
    seoContent: SmdBasSrpWebApiModelsSeoData;
    dataLayer: getDatalayer.Result.Gtm['dataLayer'] & MeridianDataLayerValue;
    pulse: getDatalayer.Result.PulseDataLayerDto;
    dehydratedState: DehydratedState;
    helmetContext: Partial<FilledContext>;
    ads: Ads.Core.Config.Completed | null;
    experimentVariants: ExperimentVariant[];
  };
}

export function Frontpage({
  blogPosts,
  initialSearchRequest,
  metaTexts,
  seoContent,
  totalAbundance,
  dataLayer,
  pulse,
  experimentVariants,
}: Frontpage.Props) {

  const ssw = useMedia('(max-width: 620px)', false);
  const [variants, setVariants] = useState<ExperimentVariant[]>(experimentVariants);

  useEffect(() => {
    if (experimentVariants.length == 0) {
      let intervalId: NodeJS.Timeout;
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId); // Stop checking after 1000ms
      }, 1000);

      intervalId = setInterval(() => {
        const cookieValue = Cookies.get(PULSE_DATA_COOKIE_NAME);
        if (cookieValue !== undefined) {
          clearInterval(intervalId); // Stop checking if cookie is not undefined
          clearTimeout(timeoutId); // Clear the timeout if cookie is found before 1000ms
          //Add all variants relevant to the experiment here
          getExperimentVariants(cookieValue).then(
            (variants) => {
              setVariants(variants);
            }
          );
        }
      }, 200); // Check every 200ms
    }
  }, [experimentVariants.length]);

  return (
    <AmplitudeExperimentsContext.Provider value={variants}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta
          name="google-site-verification"
          content="PmzU04GwUWp-nFvo0avXO6pUm6bEFP8VjumHzThcbhE"
        />
        <meta name="google" content="nositelinkssearchbox" />

        <title>
          Brugte biler til salg | Køb brugt bil og nye biler - søg blandt
          +60.000 biler!
        </title>
        <meta name="title" />
        <meta
          name="keywords"
          content="bilsalg, brugte biler, brugt bil, biler, nye biler, ny bil, leasing, varevogn"
        />
        <meta
          name="description"
          content="Find brugte biler til salg på Bilbasen – Danmarks største bilmarked. Køb og salg af brugtbiler fra både forhandlere og private. Find også nye, billige biler og leasingbiler."
        />
        <meta
          name="apple-itunes-app"
          content="app-id=407218484, app-argument=https://www.bilbasen.dk"
        />

        <meta
          property="og:image"
          content="https://www.bilbasen.dk/Public/images/logo/bilbasen-200x200.png"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          href="/Public/images/72x72_bb_t_1.png?v=1.0"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="google" content="nositelinkssearchbox" />
        <link rel="canonical" href="https://www.bilbasen.dk" />

        <link rel="dns-prefetch" href="https://api.bilbasen.dk" />
        <link rel="dns-prefetch" href="//www.googletagmanager.com" />
      </Head>
      <main style={{ padding: '16px', backgroundColor: '#f3f2f1' }}>
        <div style={{ backgroundColor: 'white', padding: '24px 16px' }}>
          <h1
            style={{
              textAlign: 'center',
              paddingTop: ssw ? '0px' : '24px',
              margin: '0px',
              lineHeight: '1.125em',
            }}
          >
            Danmarks største markedsplads for biler
          </h1>
          {(totalAbundance ?? 0) > 0 && (
            <p
              style={{ textAlign: 'center', margin: '8px 0 24px 0' }}
              className="subtitle"
            >
              {formatNumber(totalAbundance)} annoncer i dag
            </p>
          )}
          <SearchFilters
            initialSearchRequest={initialSearchRequest}
            metaTexts={metaTexts}
          />
          <CategoryList categoryTitle="Populære søgninger">
            {categoryData.map((category) => (
              <CategoryImage
                key={category.id}
                id={category.id}
                href={category.href}
                imageTitle={category.imageTitle}
                imageSrc={images[category.id] as string}
              />
            ))}
          </CategoryList>
        </div>
        <ClickoutCard />
        <KlikXtraCars />
        <NewsletterSubscription />
        <ExtraPromotedCars />
        <Blog posts={blogPosts ?? []} />
        <NewestCars />
        <PopularCars />
        <LinkGroup />
      </main>
    </AmplitudeExperimentsContext.Provider>
  );
}

// FIXME: Either move the fetchMetaTexts to a separate file (and refactor it also in the [...path].tsx) or align it with the other fetches done here in getServersideProps (fx: the way that the fetchUserDetailsWithTimeout is done)
/**
 * We create a globalQueryClient for data shared across request e.g. filter popovers/notices
 * In addition, we make a timeout in the fetch, so that we wait at most META_TEXT_FETCH_TIMEOUT_MS for the texts
 */
const globalQueryClient = new QueryClient();
const fetchMetaTexts = () =>
  new Promise<any>((res) => {
    setTimeout(
      () => res({}),
      Number(process.env.META_TEXT_FETCH_TIMEOUT_MS ?? 1000)
    );

    try {
      const textPromise = globalQueryClient.fetchQuery({
        queryKey: ['texts'],
        queryFn: () => getMetaTexts(),
        staleTime: Number(process.env.META_TEXT_STALE_MIN ?? 5) * 1000 * 60, // Cache will be updated if older than this
        cacheTime:
          Number(process.env.META_TEXT_CACHE_CLEAN_MIN ?? 60 * 2) * 1000 * 60, // Cache will be cleared if not used within this period
      });
      textPromise.then(res);
    } catch (error) {
      res({});
    }
  });

// let latestSuccessfulBlogPostsResponse: any = [];

const fetchBlogPosts = async () =>
  new Promise<any>((res) => {
    const blogPostTimer = setTimeout(() => {
      console.log('Timeout when fetching blog posts');
      //   // res(latestSuccessfulBlogPostsResponse);
      res([]);
    }, 1000);

    try {
      const blogPromise = globalQueryClient.fetchQuery({
        queryKey: ['blogPosts'],
        queryFn: () => getBlogPosts(),
        staleTime: 5 * 1000 * 60, // Cache will be updated if older than this
      });
      blogPromise.then((data) => {
        // latestSuccessfulBlogPostsResponse = data;
        clearTimeout(blogPostTimer);
        res(data);
        return data;
      });
    } catch (err: unknown) {
      console.error('Error fetching blog posts', err);
      return { error: err };
    }
  });

const fetchAbundance = async () =>
  new Promise<number>((res) => {
    const timerId = setTimeout(() => {
      console.log('Timeout when fetching abundance');
      //   // res(latestSuccessfulBlogPostsResponse);
      res(0);
    }, 15000);

    try {
      const abundancePromise = globalQueryClient.fetchQuery({
        queryKey: ['abundance'],
        queryFn: () => getAbundance(),
        staleTime: 5 * 1000 * 60, // Cache will be updated if older than this
      });
      abundancePromise.then((data) => {
        clearTimeout(timerId);
        res(data);
      });
    } catch (err: unknown) {
      console.error('Error fetching abundance', err);
      return { error: err };
    }
  });

const fetchDatalayer = async (headers?: IncomingHttpHeaders) =>
  new Promise<getDatalayer.Result>((res) => {
    const dataLayerTimer = setTimeout(() => {
      console.log('Timeout when fetching dataLayer');
      res({});
    }, 1000);
    try {
      const dataLayerPromise = globalQueryClient.fetchQuery({
        queryKey: ['dataLayer'],
        queryFn: () => getDatalayer(headers)
      });
      dataLayerPromise.then((data) => {
        clearTimeout(dataLayerTimer);
        res(data);
      });
    } catch (err: unknown) {
      console.error('Error fetching dataLayer', err);
    }
  });

const fetchUserDetails = (headers?: IncomingHttpHeaders) =>
    new Promise<UserDetailsDto>((res) => {
      const timeoutId = setTimeout(() => res({}), 1000);
      getUserDetails(headers).then((data) => {
        clearTimeout(timeoutId);
        res(data);
      });
    });

export const getServerSideProps: GetServerSideProps<Frontpage.Props> = async (context) => {
  const queryClient = new QueryClient();

  const klikXtraCarsPromise = queryClient.prefetchInfiniteQuery({
    queryKey: ['klikXtraCars'],
    queryFn: ({ pageParam = 0 }) => GetKlikXtraCars(pageParam, 12),
  });

  const userPromise = fetchUserDetails(context.req.headers);
  const baseDataLayerPromise = fetchDatalayer(context.req.headers);

  // Merge the base dataLayer with the user-specific dataLayer:
  const dataLayerPromise = (async () => {
    const [baseDataLayerResult, user] = await Promise.allSettled([baseDataLayerPromise, userPromise]);
    const baseDataLayer = baseDataLayerResult.status === 'fulfilled' ? baseDataLayerResult.value.gtm?.dataLayer ?? {} : {};
    const userSpecificDataLayer = user.status === 'fulfilled' ? user.value.dataLayer ?? {} : {};
    return Object.assign({}, baseDataLayer, userSpecificDataLayer);
  })();

  const adsPromise = dataLayerPromise.then(dataLayer => fetchAds(dataLayer, context));

  const [
    abundance,
    results,
    metaTexts,
    user,
    klikXtraCars,
    blogPosts,
    baseDataLayerResult,
    dataLayer,
    ads,
  ] = await Promise.allSettled([
    fetchAbundance(),
    getSearchFiltersByUrl(undefined, undefined, context.req.headers),
    fetchMetaTexts(),
    userPromise,
    klikXtraCarsPromise,
    fetchBlogPosts(),
    baseDataLayerPromise,
    dataLayerPromise,
    adsPromise,
  ]);

  if (results.status !== 'rejected' && results.value?.searchRequest) {
    queryClient.setQueryData(
      ['frontpageSearchFilters', results.value.searchRequest],
      results.value
    );
  }

  if (user.status === 'fulfilled') {
    queryClient.setQueryData(['user'], user.value);
  }

  const dehydratedState: DehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient))); // TODO: Avoid undefined values
  queryClient.clear(); // Clear cache after dehydrating
  const helmetContext: Partial<FilledContext> = {};

  const pulseDataCookie = Object.keys(context.req.cookies).reduce((acc, key) => {
    if (key == PULSE_DATA_COOKIE_NAME) {
      acc[key] = context.req.cookies[key]!;
    }
    return acc;
  }, {} as Record<string, string>);

  const experimentVariants = await getExperimentVariants(pulseDataCookie[PULSE_DATA_COOKIE_NAME])

  return {
    props: {
      dehydratedState,
      helmetContext,
      initialSearchRequest:
        results.status !== 'rejected' && results.value?.searchRequest
          ? results.value.searchRequest
          : {},
      dataLayer: dataLayer.status === 'fulfilled' ? dataLayer.value : {},
      pulse: baseDataLayerResult.status === 'fulfilled' ? baseDataLayerResult.value.pulseDataLayerDto ?? {} : {},
      blogPosts: blogPosts.status === 'fulfilled' ? blogPosts.value : [],
      metaTexts: metaTexts.status === 'fulfilled' ? metaTexts.value : {},
      totalAbundance: abundance.status === 'fulfilled' ? abundance.value : 0,
      seoContent: {},
      ads: ads.status === 'fulfilled' ? ads.value : null,
      experimentVariants,
    } satisfies Frontpage.Props,
  };
};

export default Frontpage;
