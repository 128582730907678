import React from 'react';
import styles from './PopularCars.module.scss';
import popularCars from './data/popularCars.json';

type CarModel = {
  model: string;
  href: string;
};

type CarBrand = {
  make: string;
  href: string;
  models: CarModel[] | { name: string; href: string }[];
};

export const PopularCars = () => {
  return (
    <div className={styles.container}>
      {popularCars.map((carBrand: CarBrand) => (
        <dl key={carBrand.make}>
          <dt>
            <h2>
              <a
                href={carBrand.href}
                data-track-action="PopularModelsClick"
                rel="bookmark"
                className={styles.title}
              >
                Brugte {carBrand.make}
              </a>
            </h2>
          </dt>
          {(carBrand.models as CarModel[]).map((model: CarModel) => (
            <dd key={model.model}>
              <a
                href={model.href}
                data-track-action="PopularModelsClick"
                rel="bookmark"
                className={styles.link}
              >
                {model.model}
              </a>
            </dd>
          ))}
        </dl>
      ))}
    </div>
  );
};
