import { Car } from './Gallery';
import styles from './GalleryCard.module.scss';

interface ICardProps {
  car: Car;
  rank?: number;
  onClick?: () => void;
}

export const GalleryCard = ({ car, rank, onClick }: ICardProps) => {
  return (
    <article className={styles.card}>
      <a href={car.url} onClick={onClick}>
        <div className={styles.wrapper}>
          {/*eslint-disable-next-line @next/next/no-img-element*/}
          <img alt={car.title} src={car.image} className={styles.image} />
          <div className={styles.price}>{car.price}</div>
        </div>
        <span className={styles.title}>{car.title}</span>
      </a>
    </article>
  );
};
